import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/hero'
import DivisionsSection from '../../../components/divisionsSection'

const Divisions = ({ data, location }) => {
  const heroImage = data.heroImage
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"

  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="UNICOM Global Divisions"
            alt="office blocks against blue sky"
            subtitle="UNICOM Global besteht aus über 50 Unternehmen, die in unterschiedlichsten Geschäftsbereichen tätig sind."
            type="root"
          />
        </div>

        <DivisionsSection divisions={data.allContentfulDivision.edges} btnId={btnId} />
      </Layout>
    </>
  )
}
export default Divisions

export function Head({ data, location }) {
  const heroImage = data.heroImage

  return (
    <SEO
      title="UNICOM Global divisions" 
      description="Explore the worldwide entities, divisions and brands of UNICOM Global that operate across key business industries and sectors"
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    allContentfulDivision(
      sort: { fields: [sequence], order: ASC }
      filter: {sector: {ne: "None"}}
    ) {
      edges {
        node {
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90) 
              }
            }
          }
          sector: tag
          sectorDescriptionDe
          name
          sequence
          url
        }
      }
    }
    heroImage: file(name: { eq: "divisions-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`